<template>
  <div class="meetingconfirmation">
    <div
      class="meetingconfirmation__logo"
      @click="gotToHomePage()"
    >
      <img
        src="./home-assets/img/logo/logo-white.svg"
        alt=""
      >
    </div>
    <div class="meetingconfirmation-content">
      <div class="meetingconfirmation-content__title">
        Thank you!
      </div>
      <div class="meetingconfirmation-content__text">
        We are looking forward to talking to you soon!
      </div>
      <div class="meetingconfirmation-content__smile">
        <img
          src="./assets/images/smile.png"
          alt="smile"
          width="88"
          height="88"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Meetingconfirmation',
  mounted() {
    const [preloaderWapper] = document.getElementsByClassName('preloader-wapper');
    preloaderWapper.classList.add('loaded');
    window.fbq('trackCustom', 'Calendly Confirmation');
  },
  methods: {
    gotToHomePage() {
      this.$router.push({ name: 'index' });
    },
  },
};
</script>

<style lang="scss" scoped>

@import './assets/sass/utils/variables';

.meetingconfirmation {
  margin-top: -24px;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 30px;
  }

  &__logo {
    margin-bottom: 29.5vh;
    display: inline-block;

    @media (max-width: 767px) {
      margin-bottom: 20vh;
    }

  }

}

.meetingconfirmation-content {
  padding: 15px;

  &__title {
    margin-bottom: 34px;
    font: 56px $font-global-bold;
    color: #191927;

    @media (max-width: 767px) {
      font-size: 38px;
    }

  }

  &__text {
    margin-bottom: 33px;
    font: 18px $font-global;
    color: #909090;

    @media (max-width: 767px) {
      font-size: 24px;
    }

  }

}
</style>
<!-- Created by zas on 23.04.2021  -->
